<template>
    <div class="password-reset-init-view ViewWrapper">
        <clv-head-meta :title="$t('Password Reset')"></clv-head-meta>
        <div class="container">
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">

                    <common-box-logo></common-box-logo>

                    <!-- BODY -->
                    <div class="gk-card gk-card--core-style-1 mb-5">

                        <!-- LOADER 2020-02-04 -->
                        <element-loading :active="loading" :background-color="'rgba(255, 255, 255, 0.9)'"></element-loading>

                        <!-- TITLE -->
                        <div class="mb-4">
                            <p class="mb-0 text-center weight-3 size-4">{{ $t('Password Reset') }}</p>
                        </div>

                        <!-- FINISH (AFTER REQUEST) -->
                        <div class="alert alert-secondary C-Alert text-center" role="alert" v-if="processFinished">
                            <p class="mb-0 weight-3">{{ $t('PASSWORD_RESET_INIT_AFTER_REQUEST' )}}</p>
                        </div>

                        <!-- THE FORM -->
                        <form class="cltlr-form-label-style-1" v-on:submit.prevent="submit" data-vv-scope="passwordResetInitForm" v-if="!processFinished">
                            <div class="form-group">
                                <label for="email">{{ $t('Email') }}</label>
                                <input type="email" class="form-control" id="email" name="vv_email" v-validate="'email|required|max:254'" v-model="formFields.email">
                                <span class="small text-danger">{{ errors.first('passwordResetInitForm.vv_email') }}</span>
                            </div>
                            <div class="form-group mt-4">
                                <button type="button" class="btn btn-primary" @click="submit">{{ $t('Reset') }}</button>
                            </div>
                        </form>

                        <common-box-links></common-box-links>
                    </div>

                    <common-box-footer></common-box-footer>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { AccountService } from '../common/services/api.service';
import AccountAccessSharedMixin from './Mixin/AccountAccessSharedMixin';

export default {
    name: 'PasswordResetInitView',
    mixins: [
        AccountAccessSharedMixin
    ],
    data () {
        return {
            // Persistence / Async //////////
            loading: false,
            resetError: null,
            processFinished: false,

            // Form //////////
            formFields: {
                email: ''
            }
        };
    },
    beforeMount () {
        // If is authenticated, go to 'profile-password-reset-init'.
        this.goToRouteBasedOnAuth(true, 'profile-password-reset-init');
    },
    methods: {
        submit () {
            // Validate Form.
            this.$validator.validateAll('passwordResetInitForm').then(valid => {
                // If form is valid, proceed.
                if (valid) {
                    // Start loader.
                    this.loading = true;

                    // Reset error.
                    this.resetError = null;

                    // Prepare DTO.
                    const emailToResetPassword = this.formFields.email;

                    // Make request to Zeus to sign up new account.
                    AccountService.requestPasswordReset(emailToResetPassword).then(({ data }) => {
                        // Do nothing.
                    }).catch((reason) => {
                        // Set error.
                        this.resetError = reason;
                    }).finally(() => {
                        // Stop loader.
                        this.loading = false;
                        this.processFinished = true;
                    });
                }
            });
        }
    }
};
</script>
